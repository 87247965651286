<template>
  <b-card>
    <b-row>
      <b-col cols="21" xl="6" class="custom-card">
        <div class="d-flex justify-content-start">
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <button style="margin-top: -80px; margin-right: -2vh" class="btn btn-primary" @click="showAddIncomeModal">إضافة مبلغ للخزنة</button>
              <AddIncome ref="addIncome" @add-income="updateTotalIncome" />
              <button style="margin-top: -80px; margin-right: 3vh" class="btn btn-primary media-q-mt" @click="showAddOutcomeModal">سحب مبلغ من الخزنة</button>
              <AddOut ref="addOutcome" @add-outcome="updateTotalOutcome" />
              <button style="margin-top: -10px; margin-right: 1vh" class="btn btn-primary" @click="toggle((out = true))">المدخلات</button>
              <button style="margin-top: -10px; margin-right: 1vh" class="btn btn-primary" @click="toggle((out = false))">المخرجات</button>
            </div>
          </div>
        </div>

        <div class="stats-container">
          <div class="d-flex align-items-center mr-2">
            <b-avatar variant="light-primary" rounded>
              <feather-icon icon="DollarSignIcon" size="18" />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ formattedTotalOutcome }}
              </h5>
              <small>إجمالي المخرجات </small>
            </div>
          </div>

          <div class="d-flex align-items-center">
            <b-avatar variant="light-success" rounded>
              <feather-icon icon="TrendingUpIcon" size="18" />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ formattedTotalIncome }}
              </h5>
              <small>إجمالي المدخلات </small>
            </div>
          </div>
          <div class="d-flex align-items-center" style="padding-right: 20px">
            <b-avatar variant="light-primary" rounded>
              <feather-icon icon="PieChartIcon" size="18" />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ formattedProfit }}
              </h5>
              <small>إجمالي الربح </small>
            </div>
          </div>
        </div>
      </b-col>
      <div class="stats-custom-card">
        <statistic-card-vertical
          style="min-width: 150px; font-size: 20px; border-radius: 40%"
          icon="DollarSignIcon"
          color="info"
          :statistic="formattedTotal"
          statistic-title="إجمالي الأموال"
        />
      </div>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BButton, BAvatar, BRow, BCol } from 'bootstrap-vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AddIncome from './add-income.vue'
import AddOut from './add-out.vue'
export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    StatisticCardVertical,
    AddIncome,
    AddOut
  },
  created() {
    this.GetSafe()
    //this.toggle((out = false));
  },
  data() {
    return {
      showModal: false,
      out: false,
      total: 0,
      totalIncome: 0,
      totalOutcome: 0,
      profit: 0
    }
  },
  methods: {
    showAddIncomeModal() {
      this.$refs.addIncome.showModal()
    },
    showAddOutcomeModal() {
      this.$refs.addOutcome.showModal()
    },
    updateTotalIncome(price) {
      this.totalIncome = this.totalIncome + price
      this.total = this.totalIncome - this.totalOutcome
      this.profit = this.totalIncome - this.totalOutcome
    },
    updateTotalOutcome(price) {
      this.totalOutcome = this.totalOutcome + price
      this.total = this.totalIncome - this.totalOutcome
      this.profit = this.totalIncome - this.totalOutcome
    },

    async GetSafe() {
      try {
        const response = await this.$store.dispatch('GetSafe')
        if ((response.status = 200)) {
          this.total = response.data.total
          this.totalIncome = response.data.totalIncome
          this.totalOutcome = response.data.totalOutcome
          this.profit = this.totalIncome - this.totalOutcome
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'فشل الاتصال بالسيرفر يرجى المحاولة لاحقا',
            icon: 'TrashIcon',
            variant: 'danger'
          }
        })
      }
    },
    // AddIncome() {
    //   this.$router.push({
    //     name: "add-income",
    //   });
    // },
    AddOut() {
      this.$router.push({
        name: 'add-out'
      })
    },
    toggle(out) {
      this.$emit('toggle', out)
    },
    formattedNumber(value) {
      return value.toFixed(2) // Rounds the number to 2 decimal places
    }
  },
  computed: {
    formattedTotal() {
      if (isNaN(this.total)) {
        this.total = 0
      }
      return this.formattedNumber(this.total)
    },
    formattedTotalIncome() {
      return this.formattedNumber(this.totalIncome)
    },
    formattedTotalOutcome() {
      return this.formattedNumber(this.totalOutcome)
    },
    formattedProfit() {
      if (isNaN(this.profit)) {
        this.profit = 0
      }
      return this.formattedNumber(this.profit)
    }
  }
}
</script>

<style>
.stats-custom-card {
  margin: 3rem auto 0;
}

.stats-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
}
</style>
