<template>
  <div>
    <div style="display: flex">
      <b-form-datepicker
        class="date"
        v-model="from"
        id="datepicker-buttons"
        placeholder="من"
        today-button
        reset-button
        close-button
        locale="en"
      />
      <b-form-datepicker
        class="date"
        id="datepicker-buttonss"
        v-model="to"
        placeholder="إلى"
        today-button
        reset-button
        close-button
        locale="en"
      />
      <button
        style="max-height: 40px; margin-right: -5vh"
        class="btn btn-primary"
        @click="FilterOutputs"
      >
        <feather-icon icon="SearchIcon"></feather-icon>
      </button>
    </div>

    <!-- <b-modal
      id="modal-dangerr"
      centered
      header="test"
      header-class="justify-content-center"
      title="تأكيد الحذف"
      hide-footer
    >
      <div class="col-12 text-center">
        <p><strong>هل انت متأكد من حذف الجميع</strong>؟</p>
        <p
          v-for="payment in this.output"
          :key="payment.id.toString()"
          style="color: red"
        >
          {{ payment.id }}
        </p>
        <b-button
          variant="primary"
          size="sm"
          class="mt-2 mr-2"
          @click="DeleteAllOutput() + $bvModal.hide('modal-dangerr')"
        >تأكيد</b-button>
        <b-button
          variant="danger"
          size="sm"
          class="mt-2 ml-2"
          @click="$bvModal.hide('modal-dangerr')"
        >إلغاء</b-button>
      </div>
    </b-modal> -->

    <!-- table -->
    <vue-good-table
    :key="tableRenderKey"
      :columns="columns"
      :rows="this.output"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
    >
      <template slot="table-column" slot-scope="props">
        <span v-if="props.column.label == 'التفاصيل'">
          <span>التفاصيل</span>
          <button
            style="padding: 4px; margin-left: -37vh; margin-right: 3vh"
            class="btn btn-primary"
            @click="DownloadData()"
          >
            <feather-icon icon="FileTextIcon" size="18" />

            طباعة تقرير كامل
          </button>
          <!-- <button
      v-b-modal.modal-dangerr
     style="float:left;padding: 8px;"
      class="btn btn-danger"
    > 
      حذف الكل
    </button> -->
        </span>
      </template>

      <template slot="table-row" slot-scope="props">
        <!-- Column: Price -->
        <span v-if="props.column.field === 'price'" class="text-nowrap">
          <span class="text-nowrap">{{ props.row.price }}</span>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'" style="">
          <button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-danger"
            style="margin-right: 16px"
            class="btn btn-danger"
            @click="$bvModal.show(props.row.id.toString())"
          >
            <feather-icon icon="Trash2Icon" size="12" />
          </button>
          <b-modal
            :id="props.row.id.toString()"
            centered
            header="test"
            header-class="justify-content-center"
            title="تأكيد الحذف"
            hide-footer
          >
            <div class="col-12 text-center">
              <p>
                <strong>هل انت متأكد من الحذف</strong>
              </p>
              <b-button
                variant="primary"
                size="sm"
                class="mt-2 mr-2"
                @click="
                  DeleteOutput(props.row.id) +
                    $bvModal.hide(props.row.id.toString())
                "
                >تأكيد</b-button
              >
              <b-button
                variant="danger"
                size="sm"
                class="mt-2 ml-2"
                @click="$bvModal.hide(props.row.id.toString())"
                >إلغاء</b-button
              >
            </div>
          </b-modal>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> عرض الصفحة الأولى </span>

            <b-form-select
              v-model="pageLength"
              :options="['3', '7', '10', '15', '20']"
              class="mx-1"
               @change="onPerPageChange" 
            />
            <span class="text-nowrap"> من {{ totalCount }} العناصر </span>
          </div>
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalCount"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              class="mt-1 mb-0"
              @input="handlePageChange"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import * as XLSX from "xlsx";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BButton,
  BAvatar,
  BBadge,
  BModal,
  VBModal,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BFormDatepicker,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import Ripple from "vue-ripple-directive";
import { codeBasic } from "../code";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCardCode,
    VueGoodTable,
    BButton,
    BModal,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BFormDatepicker,
  },

  directives: {
    "b-modal": VBModal,
    Ripple,
  },
created(){
  this.FilterOutputs();
},
  data() {
    return {
      output: [],
     pageLength: 7,
      currentPage :1,
       totalCount: 0, // Total rows/items count from API
      totalPages: 0, // Total pages count from API
      dir: false,
      codeBasic,
      columns: [
        {
          label: "المبلغ",
          field: "price",
        },
        {
          label: "البيان",
          field: "details",
        },
        {
          label: "التاريخ",
          field: "date",
        },

        {
          label: "التفاصيل",
          field: "action",
          width: "28%",
        },
      ],
      from: "",
      to: "",
      searchTerm: "",
      tableRenderKey:0
    };
  },
  methods: {
    async onPerPageChange(newPerPage) {
      // Update pageLength and refresh data for the current page
      this.pageLength = parseInt(newPerPage, 10);
      await this.FilterOutputs();
    },
    async handlePageChange() {
      // Fetch data for the current page
      await this.FilterOutputs();
    },
    async FilterOutputs() {
      if(this.from === ""|| this.to ===""){
       let today = new Date();
        today.setHours(0, 0, 0, 0); // Set time to 12:00 AM
        this.from = today;
        let nextDay = new Date();
        nextDay.setDate(nextDay.getDate() + 1); // Add 1 day
        nextDay.setHours(0, 0, 0, 0); // Set time to 12:00 AM
        this.to = nextDay;
      }
      const payload = {
         startDate: this.from,
        endDate: this.to,
         page:this.currentPage,
        pageSize:this.pageLength
      };

      try {
        const response = await this.$store.dispatch(
          "FilterSafeOutputs",
          payload
        );
         if ((response.data.isSuccess)) {
          this.output = response.data.result;
          this.tableRenderKey++;
           this.totalCount = response.data.totalCountData;
        this.totalPages = response.data.pagesCount;
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "فشل الاتصال بالسيرفر يرجى المحاولة لاحقا",
            icon: "TrashIcon",
            variant: "danger",
          },
        });
      }
    },
    async DownloadData() {
      // Check if either from or to is falsy

      if (this.output.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "لا يوجد بيانات للطباعة",
            icon: "InfoIcon",
            variant: "warning",
          },
        });
        return; // End the function if either from or to is falsy
      }

      const that = this;
      that.jsonData = that.output;
      for (var i = 0; i < that.jsonData.length; i++) {
        delete that.jsonData[i].id;
        delete that.jsonData[i].agentId;
        Object.keys(that.jsonData[i]).forEach((key, index) => {
          that.jsonData[i][that.columns[index].label] = that.jsonData[i][key];
          delete that.jsonData[i][key];
        });
      }
      const data = XLSX.utils.json_to_sheet(that.jsonData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(wb, "تقرير-مخرجات-الخزنة.xlsx");
      await that.GetSafe();
    },
    async GetSafe() {
      try {
        const response = await this.$store.dispatch("GetSafe");
        if ((response.status = 200)) {
          this.total = response.data.total;
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "فشل الاتصال بالسيرفر يرجى المحاولة لاحقا",
            icon: "TrashIcon",
            variant: "danger",
          },
        });
      }
    },
    DeleteAllOutput() {},
    async DeleteOutput(id) {
      
         try {
 

        const response = await this.$store.dispatch(
          "RemoveSafeOutput",
          id
        );
        if ((response.data.isSuccess)) {

        this.$toast({
            component: ToastificationContent,
            props: {
              title:  'تم الحذف بنجاح',
              icon: "CheckIcon",
              variant: "success",
            },
          });
     
        window.location.reload();
        }
        }
       catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "فشل الاتصال بالسيرفر يرجى المحاولة لاحقا",
            icon: "TrashIcon",
            variant: "danger",
          },
        });
      }

    },
    
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
   
  },
};
</script>

<style lang="scss" scoped>
.date {
  max-width: 400px;
  padding: 1;
  margin-right: 9vh;
  margin-bottom: 4vh;
}
.vgt-left-align {
  max-width: 100px;
}
</style>
