<template>
  <b-modal centered ref="modal" title="اضافة خرج" hide-footer>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent>
        <b-row cols="1">
          <b-col>
            <b-form-group label="المبلغ" label-for="v-price">
              <validation-provider #default="{ errors }" name="المبلغ" rules="required">
                <b-form-input id="v-price" v-model.number="price" type="number" :state="errors.length > 0 ? false : null" placeholder=" المبلغ بالجنيه" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="التاريخ" label-for="v-date">
              <validation-provider #default="{ errors }" name="التاريخ" rules="required">
                <b-form-datepicker
                  size="lg"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  v-model="date"
                  locale="ar"
                  placeholder="تاريخ"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="التفاصيل" label-for="v-details">
              <b-form-textarea rows="4" max-rows="4" size="lg" id="v-details" v-model="details"></b-form-textarea>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="خرج لمندوب" label-for="v-agent">
              <b-form-checkbox id="v-agent" v-model="selected" />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group v-if="this.selected" label="اسم المندوب" label-for="v-agent">
              <validation-provider #default="{ errors }" name="اسم المندوب" rules="required">
                <b-dropdown
                  class="agent-dropdown"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  :text="agentName == null ? 'اسم المندوب' : agentName"
                  v-model="agentName"
                  :state="errors.length > 0 ? false : null"
                  variant="outline-primary"
                >
                  <b-dropdown-item
                    v-for="agent in agents"
                    :key="agent.id"
                    :value="agent.userDisplayName"
                    @click=";(agentName = agent.userDisplayName), (agentId = agent.id)"
                  >
                    {{ agent.userDisplayName }}
                  </b-dropdown-item>
                </b-dropdown>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- submit and reset -->
          <b-col cols="12">
            <b-button
              :disabled="isValidating"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              class=""
              @click.prevent="validationForm"
            >
              إضافه
            </b-button>
            <b-button
              :disabled="isValidating"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="danger"
              style="margin-right: 15px"
              @click="hideModal"
            >
              رجوع
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BFormDatepicker,
  BFormCheckboxGroup,
  BFormRadio,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BModal,
  BFormTextarea
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormCheckboxGroup,
    BFormRadio,
    BDropdown,
    BDropdownDivider,
    BDropdownItem,
    BCardCode,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormDatepicker,
    BModal,
    BFormTextarea
  },

  directives: {
    Ripple
  },
  props: ['id'],
  data() {
    return {
      isValidating: false,
      // ? Default locale for VeeValidate is 'en'
      locale: 'ar',
      // for validation
      required,
      price: null,
      date: null,
      details: '',
      selected: null,
      agentName: null,
      agents: [],
      agentId: null
    }
  },
  created() {
    this.GetAgents()
  },
  mounted() {
    localize(this.locale)
  },

  methods: {
    showModal() {
      this.resetForm()
      this.$refs.modal.show()
    },
    hideModal() {
      this.$refs.modal.hide()
    },
    resetForm() {
      this.price = null
      this.date = null
      this.details = ''
      this.selected = null
      this.agentName = null
      this.agentId = null
    },
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        this.isValidating = true
        if (success) {
          if (!this.agentId) {
            this.AddOut()
          } else {
            this.AddToAgent()
          }
        }
        this.isValidating = false
      })
    },
    async GetAgents() {
      try {
        const response = await this.$store.dispatch('GetAgents')
        if ((response.status = 200)) {
          this.agents = response.data
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'فشل الاتصال بالسيرفر يرجى المحاولة لاحقا',
            icon: 'TrashIcon',
            variant: 'danger'
          }
        })
      }
    },
    async AddOut() {
      const payload = {
        price: this.price,
        date: this.date,
        details: this.details
      }

      try {
        if (!this.agentId) {
          const response = await this.$store.dispatch('AddSafeOutput', payload)
          if ((response.status = 204)) {
            this.$emit('add-outcome', payload.price)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'تم السحب بنجاح',
                icon: 'CheckIcon',
                variant: 'success'
              }
            })
            this.$refs.modal.hide()
          }
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'فشل الاتصال بالسيرفر يرجى المحاولة لاحقا',
            icon: 'TrashIcon',
            variant: 'danger'
          }
        })
      }
    },
    async AddToAgent() {
      const payload = { agentId: this.agentId, value: this.price, date: this.date }
      try {
        const response = await this.$store.dispatch('AddCostToAgent', payload)
        if ((response.status = 204)) {
          this.$emit('add-outcome', payload.price)

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'تم السحب بنجاح',
              icon: 'CheckIcon',
              variant: 'success'
            }
          })
          this.$refs.modal.hide()
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'فشل الاتصال بالسيرفر يرجى المحاولة لاحقا',
            icon: 'TrashIcon',
            variant: 'danger'
          }
        })
      }
    },
    back() {
      this.$router.push({
        name: 'safe'
      })
    }
  }
}
</script>
<style scoped>
.form-group {
  font-size: 1.857rem;
}
</style>
<style>
[dir] .card {
  border: none;
}
/* Custom styles for the agent dropdown */
.agent-dropdown .dropdown-menu {
  max-height: 200px; /* Adjust this value as needed */
  overflow-y: auto; /* Enables vertical scrolling */
}
</style>
