<template>
  <b-card-code title="" style="margin-top: 0px">
    <safe-card @toggle="ToggleTables" />
    <div v-if="toggleTables">
      <income />
    </div>
    <div v-else>
      <out />
    </div>
    <template #code>
      {{ codeBasic }}
    </template>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BButton,
  BAvatar,
  BBadge,
  BModal,
  VBModal,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BFormDatepicker,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import Ripple from "vue-ripple-directive";
import { codeBasic } from "../code";
import safeCard from "./safe-card.vue";
import income from "./income.vue";
import out from "./out.vue";

export default {
  components: {
    "safe-card": safeCard,
    income: income,
    out: out,
    BCardCode,
    VueGoodTable,
    BButton,
    BModal,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BFormDatepicker,
  },

  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      pageLength: 7,
      dir: false,
      codeBasic,
      columns: [
        {
          label: "السعر",
          field: "price",
        },
        {
          label: "التاريخ",
          field: "date",
        },
        {
          label: "البيان",
          field: "details",
        },
        {
          label: "التفاصيل",
          field: "action",
        },
      ],
      from: "",
      to: "",
      searchTerm: "",
      toggleTables: false,
    };
  },
  
  methods: {
    ToggleTables(value) {
      this.toggleTables = value;
    },
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
};
</script>
